<template>
  <div class="affiliatePage">
    <div class="container container-fluid">
      <div class="bar-back">
        <router-link to="/member/main"
          ><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link
        >
      </div>
      <div class="p-2 w-100 bg-light main-content align-self-stretch">
        <div
          class="
            bgwhitealpha
            text-secondary
            shadow-sm
            rounded
            p-1
            px-2
            xtarget
            col-lotto
            d-flex
            flex-row
            mb-1
            pb-0
          "
        >
          <div class="lotto-title">
            <h4><i class="fas fa-thumbs-up"></i> แนะนำเพื่อน</h4>
          </div>
        </div>
        <div class="row px-0 mb-1 mx-0">
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link
              to="/member/affiliate"
              class="
                btn-af btn btn-outline-primary btn-block
                d-flex
                flex-column
                active
              "
            >
              <div class="affiliate-icon">
                <i class="far fa-handshake" />
              </div>
              ภาพรวม
            </router-link>
          </div>
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link
              to="/member/affiliate/members"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column"
            >
              <div class="affiliate-icon">
                <i class="fas fa-chalkboard-teacher" />
              </div>
              ที่สมัคร
            </router-link>
          </div>
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link
              to="/member/affiliate/revenue"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column"
            >
              <div class="affiliate-icon">
                <i class="fas fa-money-check-alt" />
              </div>
              รายได้
            </router-link>
          </div>
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link to="/member/affiliate/withdraw"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column"
            >
              <div class="affiliate-icon">
                <i class="fas fa-hand-holding-usd" />
              </div>
              ถอนรายได้
            </router-link>
          </div>
        </div>
        <div class="w-100 my-2 border-bottom"></div>

        <div
          class="
            table-secondary
            border border-secondary
            shadow-sm
            rounded
            p-0
            mb-1
            mx-1
          "
        >
          <div class="row px-0 mx-0">
            <div class="col-12 col-sm-12 col-md-2 p-1">
              <div
                class="
                  p-1
                  text-center
                  rounded
                  bg-secondary
                  text-white
                  h-100
                  d-flex
                  flex-row flex-sm-row flex-md-column
                  justify-content-center
                  align-items-center
                "
              >
                <small class="mr-1">ส่วนแบ่งรายได้:</small
                ><span class="text-warning">8%</span>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-2 p-1">
              <div class="py-1 text-center rounded bg-white">
                <small>รายได้ทั้งหมด</small>
                <h6 class="text-primary thb mb-0">฿ {{ getTotalIncome }}</h6>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-2 p-1">
              <div class="py-1 text-center rounded bg-white">
                <small>รายได้ปัจจุบัน</small>
                <h6 class="text-success thb mb-0">฿ {{ getCurrentIncome }}</h6>
              </div>
            </div>
            <div class="col-4 col-sm-4 col-md-2 p-1">
              <div class="py-1 text-center rounded bg-white">
                <small>สมาชิกแนะนำ</small>
                <h6 class="text-info mb-0">{{ getDownline }}</h6>
              </div>
            </div>
            <div class="col-4 col-sm-4 col-md-2 p-1">
              <div class="py-1 text-center rounded bg-white">
                <small>แทงทั้งหมด</small>
                <h6 class="text-info thb mb-0">{{ numberWithCommas(getTotalBet) }}</h6>
              </div>
            </div>
            <div class="col-4 col-sm-4 col-md-2 p-1">
              <div class="py-1 text-center rounded bg-white">
                <small>คลิกทั้งหมด</small>
                <h6 class="text-info mb-0">{{ getTotalClick }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            bgwhitealpha
            text-secondary
            shadow-sm
            rounded
            p-2
            mb-1
            xtarget
            col-lotto
          "
        >
          <h4>
            <i class="fas fa-share-alt-square"></i> ลิ้งค์แนะนำ และแบนเนอร์
          </h4>
          <div class="card">
            <h5 class="card-header">ลิ้งสำหรับโปรโมท</h5>
            <div class="card-body">
              <textarea
                id="afurl"
                v-bind:value="pathname + getAffiliateLink"
                cols="30"
                rows="2"
                class="form-control text-center mb-1"
                readonly="true"
              >
              </textarea>
              <button
                type="button"
                data-clipboard-target="#afurl"
                class="btn btn-primary btn-block m-1"
                @click="copyURL"
              >
                <i class="fas fa-copy"></i> คัดลอกลิงค์แนะนำ
              </button>

              <br />
            </div>
          </div>
        </div>
        <div
          class="
            bgwhitealpha
            text-secondary
            shadow-sm
            rounded
            p-2
            mb-5
            xtarget
            col-lotto
          "
        >
          <h4>
            <i class="fas fa-project-diagram"></i> ลิ้งค์ช่วยแชร์ ช่วยแนะนำ
          </h4>
          <div class="alert alert-primary text-center">
            <h6>ลิ้งค์ช่วยแชร์รับ 8% ฟรี!</h6>
            (เพียงก๊อปปี้ลิ้งค์ไปแชร์ก็ได้เงินแล้ว)
            <br />ยิ่งแชร์มากยิ่งได้มาก
          </div>
          <p>
            ท่านสามารถนำลิ้งค์ด้านล่างนี้หรือนำป้ายแบนเนอร์ ไปแชร์ในช่องทางต่างๆ
            ไม่ว่าจะเป็น เว็บไชต์ส่วนตัว, Blog, Facebook หรือ Social Network
            อื่นๆ หากมีการสมัครสมาชิกโดยคลิกผ่านลิ้งค์ของท่านเข้ามา
            ลูกค้าที่สมัครเข้ามาก็จะอยู่ภายให้เครือข่ายของท่านทันที
            และหากลูกค้าภายใต้เครือข่ายของท่านมีการเดิมพันเข้ามา
            ทุกยอดการเดิมพัน ท่านจะได้รับส่วนแบ่งในการแนะนำ 8%
            ทันทีโดยไม่มีเงื่อนไข
          </p>
          <p><b>ตัวอย่างดังนี้</b></p>
          <ul class="list-group">
            <li class="list-group-item">
              ลูกค้าท่าน 1 คน แทง 1,000 บาท ท่านจะได้ 80 บาท
            </li>
            <li class="list-group-item">
              ลูกค้าท่าน 10 คน แทง 1,000 บาท ท่านจะได้รับ 800 บาท
            </li>
            <li class="list-group-item">
              ลูกค้าท่าน 100 คน แทง 1,000 บาท ท่านจะได้รับ 8,000 บาท
            </li>
          </ul>
          <br />
          <p>
            สามารถทำรายได้เดือน 100,000 บาทง่ายๆเลยทีเดียว
            เพราะทางเรามีหวยเปิดรับทายผลทุกวัน มีมากกว่าวันละ 200 รอบ
            เปิดรับแทงออนไลน์ตลอด 24 ชม.
            และรายได้ทุกบาททุกสตางค์ของท่านสามารถตรวจสอบได้ทุกขั้นตอน
            งานนี้แจกจริง จริงจ่าย ที่นี้ที่เดียวที่ให้คุณมากกว่าใคร
            ก๊อปปี้ลิ้งค์และข้อความด้านล่างนี้ นำไปแชร์ได้เลย
          </p>
          <p>
            <strong>หมายเหตุ:</strong
            >&nbsp;รายได้การช่วยแชร์ช่วยแนะนำของท่านสามารถแจ้งถอนได้ทุกเวลา
            หากมียอดรายได้มากกว่า 500 บาทขึ้นไป
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      pathname: window.location.origin
    }
  },
  mounted () {
    // this.pathname = this.pathname + this.getAffiliateLink
    // alert(this.pathname)
  },
  methods: {
    ...mapActions(['setLoading', 'getAffiliateInfo']),
    numberWithCommas (n) {
      const x = Number(n)
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    copyURL () {
      const el = document.getElementById('afurl')
      el.select()
      document.execCommand('copy')
      this.makeToast(true)
    },
    makeToast (append = false) {
      this.$bvToast.toast('คัดลอกลิงค์แนะนำเรียบร้อยแล้ว', {
        noCloseButton: true,
        autoHideDelay: 1000,
        variant: 'success',
        appendToast: append,
        solid: false
      })
    }
  },
  async created () {
    this.setLoading(true)
    await this.getAffiliateInfo()
    this.setLoading(false)
  },
  computed: {
    ...mapGetters([
      'getAffiliateLink',
      'getCurrentIncome',
      'getTotalClick',
      'getTotalIncome',
      'getDownline',
      'getTotalBet'
    ])
  }
}
</script>

<style>
</style>
